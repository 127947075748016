import { dealImages } from "@/assets";
import ResponsiveLazyImage from "@/components/responsive-lazy-image";
import SectionElement from "@/components/section-element";
import { dataMenuLabels } from "@/data/menu.data";
import { FC } from "react";
import styles from "./deal.module.scss";
import { dealData } from "@/data/deal.data";
import { CTAButton, Conditional } from "@/components";
import DataLayer from "@/utils/DataLayer";

const reference = dataMenuLabels().find((c) => c.slug === "deal");

export const DealSection: FC = () => {
  return (
    <Conditional notOn="mobile">
      <SectionElement
        id="Deal"
        className={styles.container}
        navReference={reference}
        noPadding
      >
        <div className={styles.content}>
          {dealData.title}
          <div className={styles.ctas}>
            {dealData.ctas.map(({ label, href, slug }) => {
              return (
                <CTAButton
                  key={`cta-dealer-${slug}`}
                  className={styles.cta}
                  href={href}
                  text={label}
                  title={label}
                  handleClick={() => {
                    DataLayer.clickEvent({
                      element: `${slug}`,
                      elementCategory: "cta",
                      pageSection: "conteudo",
                      pageSubsection: "vamos-negociar",
                    });
                  }}
                />
              );
            })}
          </div>
        </div>

        <ResponsiveLazyImage
          className={styles.dealBackground}
          alt="Ofertas"
          title="Ofertas"
          src={dealImages.backgroundImage}
        />
      </SectionElement>
    </Conditional>
  );
};
