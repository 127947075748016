import productDetails, {
  ProductTab,
  ProductTabSlide,
} from "@/data/product-details.data";
import {FC, useState, Fragment, useContext, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import styles from "./swiper-product-details-mobile.module.scss";
import { observer } from "mobx-react-lite";
import {
  ArrowButton,
  Chevron,
  ResponsiveLazyImage,
  VimeoEmbed,
} from "@/components";
import scssStyles from "@/utils/scssStyles";
import DataLayer from "@/utils/DataLayer";
import getRangeOfEntries from "@/utils/getRangeOfEntries";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import { Context } from "@/context";
import {tecnologia} from "@assets/productDetails";

SwiperCore.use([A11y, Keyboard, Thumbs]);

const pageSubsection = "tudo-sobre-argo";
interface SlideProps {
  slide: ProductTabSlide;
}

interface ISetReference {
  setReference: (tab?: ProductTab) => void;
}
const Slide: FC<SlideProps> = observer(({ slide }) => {
  return (
    <div className={styles.slideContainer}>
      {slide.vimeoId?.mobile ? (
        <div className={styles.videoWrapper}>
          <VimeoEmbed id={slide.vimeoId.mobile} title={slide.titleSEO} />
        </div>
      ) : (
        <ResponsiveLazyImage
          alt={slide.titleSEO}
          title={slide.titleSEO}
          src={slide.image.fullPath}
          src2={slide.image.fullPath2x}
          src3={slide.image.fullPath3x}
          containerClassName={styles.slideImageContainer}
          className={styles.slideImage}
        />
      )}
      <div className={styles.slideDetails}>
        <h2>{slide.title}</h2>
        <div className={styles.slideDescription}>{slide.descriptionMobile}</div>
      </div>
    </div>
  );
});

interface TabProps extends ISetReference {
  tab: ProductTab;
  rerender: React.Dispatch<React.SetStateAction<boolean>>;
}

const Tab: FC<TabProps> = ({ tab, rerender, setReference }) => {
  const [touchLocation, setTouchLocation] = useState<PointerEvent["clientX"]>();
  const { state, dispatch } = useContext(Context);
  const bulletProps = getRangeOfEntries(
    state.layout.mobileSwiperController[tab.id]?.activeIndex,
    tab.children
  );
  const currentTab =
    (state.layout.selectedDetailTab as { id: string })?.id === tab.id;

  const activeIndex =
    state.layout.mobileSwiperController[tab.id]?.activeIndex ?? 0;
  const getMaxHeight = (id: string) => {
    if (state.layout.selectedDetailTab === id) {
      return 700;
    }
    return 0;
  };
  const handleSelectedDetailTab = (tab: null | ProductTab): void => {
    if (tab) {
      dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });
    }

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };
  const handleSwiper = (e: SwiperCore | null, tabId: string): void => {
    dispatch({
      type: 'SET_MOBILE_SWIPER_CONTROLLER',
      payload: {
        productDetailsMobileSwiperController: e,
        tabId,
      },
    });
  };



  return (
    <>
      <button
        className={scssStyles([
          "accordion",
          "noFocus",
          state.layout.selectedDetailTab === tab.id ? "active" : "",
          styles.accordionTopic,
        ])}
        title={currentTab ? "Fechar" : "Expandir"}
        onClick={() => {
          DataLayer.clickEvent({
            element: tab.title,
            elementCategory: "botao",
            pageSection: pageSubsection,
            pageSubsection,
          });
            dispatch({
              type: "SET_DETAIL_TAB",
              payload: {
                isMobile: true,
                selectedDetailTab: currentTab ? null : tab,
              },
            });
          handleSelectedDetailTab(
              state.layout.selectedDetailTab === tab.id ? null : tab
          );
      
        }}
      >
        <Chevron
          bigChevron
          stroke={3}
          color="#393939"
          direction={currentTab ? "up" : "down"}
        />
        <p>{tab.title}</p>
      </button>
      <div
        id={`panel-${tab.id}`}
        className={scssStyles(["panel", styles.topicAnswer])}
        style={{
          maxHeight: getMaxHeight(tab.id),
        }}
      >
        <div id={`panel-body-${tab.id}`}>
          <div className={styles.controls}>
            <ArrowButton
              previous
              disabled={
                state.layout.mobileSwiperController[tab.id]?.activeIndex === 0
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "anterior",
                  elementCategory: "icone",
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.mobileSwiperController[tab.id]?.slidePrev();
              }}
            />
            <div className={styles.bullets}>
              {!!bulletProps.before &&
                Array.from(Array(bulletProps.before).keys())
                  .slice(0, 2)
                  .map((_, i) => (
                    <button
                      key={`smaller-bullet-ProductDetails-before-${i}`}
                      className={scssStyles([
                        styles.bullet,
                        i == 1 || bulletProps.before === 1
                          ? "mediumBullet"
                          : "smallerBullet",
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `item-${activeIndex - 2}`,
                          elementCategory: "icone",
                          pageSection: pageSubsection,
                          pageSubsection,
                        });
                        if (
                          state.layout.mobileSwiperController &&
                          state.layout.mobileSwiperController[tab.id]
                        ) {
                          state.layout.mobileSwiperController[tab.id]?.slideTo(
                            activeIndex - 2
                          );
                        }
                      }}
                    >
                      <span />
                    </button>
                  ))}
              {tab.children.map((c, index) => {
                if (bulletProps.range.indexOf(index) === -1) return null;
                return (
                  <button
                    key={`bullet-s-j-${c.id}`}
                    title={c.titleSEO}
                    className={scssStyles([
                      styles.bullet,
                      state.layout.mobileSwiperController[tab.id]
                        ?.activeIndex === index
                        ? styles.active
                        : "",
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `item-${activeIndex - 2}`,
                        elementCategory: "icone",
                        pageSection: pageSubsection,
                        pageSubsection: tab.slug,
                      });
                      if (
                        c.index &&
                        state.layout.mobileSwiperController &&
                        state.layout.mobileSwiperController[tab.id]
                      ) {
                        state.layout.mobileSwiperController[tab.id]?.slideTo(
                          index
                        );
                      }
                    }}
                  >
                    <span />
                  </button>
                );
              })}
              {!!bulletProps.after &&
                Array.from(Array(bulletProps.after).keys())
                  .slice(0, 2)
                  .map((_, i) => (
                    <button
                      key={`smaller-bullet-ProductDetails-after-${i}`}
                      className={scssStyles([
                        styles.bullet,
                        i == 0 || bulletProps.after === 1
                          ? "mediumBullet"
                          : "smallerBullet",
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `item-${activeIndex - 2}`,
                          elementCategory: "icone",
                          pageSection: pageSubsection,
                          pageSubsection,
                        });
                        if (
                          state.layout.mobileSwiperController &&
                          state.layout.mobileSwiperController[tab.id]
                        ) {
                          state.layout.mobileSwiperController[tab.id]?.slideTo(
                            activeIndex + 2
                          );
                        }
                      }}
                    >
                      <span />
                    </button>
                  ))}
            </div>
            <ArrowButton
              disabled={
                (state.layout.mobileSwiperController[tab.id]?.activeIndex ??
                  tab.children.length - 1) >=
                tab.children.length - 1
              }
              handleClick={() => {
                DataLayer.clickEvent({
                  element: "proximo",
                  elementCategory: "icone",
                  pageSection: pageSubsection,
                  pageSubsection: tab.slug,
                });
                state.layout.mobileSwiperController[tab.id]?.slideNext();
              }}
            />
          </div>
          <Swiper
            observer
            observeParents
            parallax
            onSwiper={(e) => handleSwiper(e, tab.id)}
            controller={
              state.layout.mobileSwiperController[tab.id]
                ? { control: state.layout.mobileSwiperController[tab.id] }
                : undefined
            }
            onSlideChange={(e) => {
              const index = e.activeIndex;
              const tabSelected = productDetails.find((p) => p.id === tab.id);
              handleSwiper(e, tab.id);
              setReference(tabSelected);
              rerender((r) => !r);
            }}

            onTouchStart={(_, event) => {
              const clientX = getClientXFromDifferentEvents(event);
              setTouchLocation(clientX);
            }}
            onTouchEnd={(_, event) => {
              const clientX = getClientXFromDifferentEvents(event);
              DataLayer.swipeEvent({
                element:
                  (touchLocation ?? 0) > clientX ? "proximo" : "anterior",
                elementCategory: "slide",
                pageSection: pageSubsection,
                pageSubsection: tab.title,
              });
            }}
          >
            {tab.children.map((slide) => (
              <SwiperSlide key={`${tab.id}-x-0-${slide.id}`}>
                <Slide slide={{ ...slide, tabId: tab.id }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

const SwipersMobile: FC<ISetReference> = ({ setReference }) => {
  const [, rerender] = useState(false);
  return (
    <div className={styles.container}>
      {productDetails.map((tab) => (
        <Fragment key={`sw-frag-${tab.id}`}>
          <Tab tab={tab} rerender={rerender} setReference={setReference} />
        </Fragment>
      ))}
    </div>
  );
};

export default SwipersMobile;
