import { IVersion } from "@/models";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import {
  Chevron,
  ResponsiveLazyImage,
  VersionsOrnament,
} from "@/components";
import { versions, versionsFeatures } from "@/assets";
import styles from "./version-slide.module.scss";
import { IncludePrefix } from "@/utils/imagePrefixes";
import { links } from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";
import { cars } from "../../v3/version";
interface VersionSlideProps {
  version: IVersion;
  load: boolean;
}

export const VersionSlide: FC<VersionSlideProps> = observer(
  ({ version, load }) => {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const [, updater] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const refRight = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const updateDom = () => {
        updater((u) => !u);
      };
      window?.addEventListener("resize", updateDom);

      return () => {
        window?.removeEventListener("resize", updateDom);
      };
    }, []);

    return (
      <div ref={ref} className={styles.slideContainer}>
        <div className={styles.productImageContainer}>
          <VersionsOrnament />
          <ResponsiveLazyImage
            alt={version.fullName}
            src={versions[version.id].fullPath}
            src2={versions[version.id].fullPath2x}
            src3={versions[version.id].fullPath3x}
          />
        </div>
        <div className={styles.featContainer}>
          <div className={styles.nameContainer}>
            <h3 className={styles.firstName}>{version.nameParts.first}</h3>
            {!!version.nameParts.second && (
              <p className={styles.secondName}>{version.nameParts.second}</p>
            )}
          </div>
          <div className={styles.listThumb}>
            {version.details.map((detail, index) => (
              <div key={detail[0]} className={styles.item}>
                <div className={styles.thumb}>
                  <ResponsiveLazyImage
                    src={
                      (
                        versionsFeatures[version.id][
                          detail[1] as keyof IncludePrefix
                        ] as unknown as { fullPath: string }
                      ).fullPath
                    }
                    src2={
                      (
                        versionsFeatures[version.id][
                          detail[1] as keyof IncludePrefix
                        ] as unknown as { fullPath2x: string }
                      ).fullPath2x
                    }
                    src3={
                      (
                        versionsFeatures[version.id][
                          detail[1] as keyof IncludePrefix
                        ] as unknown as { fullPath3x: string }
                      ).fullPath3x
                    }
                    alt={detail[0]}
                  />
                </div>
                <div className={styles.description}>
                  <p>
                    {detail[0].split("|")[0]} <br />
                    {detail[0].split("|")[1]}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.buttonGroup}>
            <a
              onClick={() => {
                DataLayer.clickEvent({
                  element: "compre-o-seu",
                  elementCategory: "cta",
                  pageSection: "conteudo",
                  pageSubsection: "versoes",
                });
              }}
              target="_blank"
              href={links.compre}
            >
              <span>
                <em>Compre o seu</em>
              </span>
              <div>
                <Chevron thicker direction="right" color="#FDB72B" />
              </div>
            </a>
            <a
              onClick={() => {
                DataLayer.clickEvent({
                  element: "monte-o-seu",
                  elementCategory: "cta",
                  pageSection: "conteudo",
                  pageSubsection: "versoes",
                });
              }}
              target="_blank"
              href={cars.find((car) => car.mvsCode === version.mvsCode)?.versionCtaButton[0].url}
            >
              <span>
                <em>Monte o seu</em>
              </span>
              <div>
                <Chevron thicker direction="right" color="#FDB72B" />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
);
