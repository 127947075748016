import { INTERNAL_360_PATH, INTERNAL_360_PATH_MOBILE } from "@/assets";
import { Image360Item } from "@/models";

export const Images360 = (): Image360Item[] => {
  return window.innerWidth <= 1024
    ? Internal360ImagesMobile
    : Internal360Images;
};

export const Internal360Images: Image360Item[] = [
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00000.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00001.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00002.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00003.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00004.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00005.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00006.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00007.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00008.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00009.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00010.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00011.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00012.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00013.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00014.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00015.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00016.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00017.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00018.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00019.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00020.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00021.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00022.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00023.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00024.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00025.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00026.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00027.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00028.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00029.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00030.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00031.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00032.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00033.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00034.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00035.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00036.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00037.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00038.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00039.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00040.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00041.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00042.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00043.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00044.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00045.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00046.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00047.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00048.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00049.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00050.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00051.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00052.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00053.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00054.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00055.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00056.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00057.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00058.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00059.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00060.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00061.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00062.jpg` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00063.jpg` },
];

export const Internal360ImagesMobile: Image360Item[] = [
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00000.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00001.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00002.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00003.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00004.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00005.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00006.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00007.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00008.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00009.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00010.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00011.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00012.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00013.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00014.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00015.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00016.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00017.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00018.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00019.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00020.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00021.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00022.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00023.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00024.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00025.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00026.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00027.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00028.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00029.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00030.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00031.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00032.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00033.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00034.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00035.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00036.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00037.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00038.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00039.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00040.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00041.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00042.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00043.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00044.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00045.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00046.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00047.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00048.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00049.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00050.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00051.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00052.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00053.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00054.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00055.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00056.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00057.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00058.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00059.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00060.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00061.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00062.jpg` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00063.jpg` },
];
