import { Conditional, ResponsiveLazyImage } from "@/components";
import { PresentationSectionProps } from "@/data/presentation-section.data";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import styles from "./presentation-slide-component.module.scss";

interface SlideProps {
  slide: PresentationSectionProps;
}

export const PresentationSlideComponent: FC<SlideProps> = observer(
  ({ slide }) => {
    return (
      <>
        <Conditional notOn="mobile">
          <div className={styles.slideContainer}>
            <div>
              <h2>{slide.title}</h2>
              <p>{slide.descriptionDesktop}</p>
            </div>
            <ResponsiveLazyImage
              alt={slide.title}
              title={slide.title}
              src={slide.image.fullPath}
              src2={slide.image.fullPath2x}
              src3={slide.image.fullPath3x}
            />
          </div>
        </Conditional>
        <Conditional notOn="desktop">
          <div className={styles.slideContainer}>
            <ResponsiveLazyImage
              alt={slide.title}
              title={slide.title}
              src={slide.image.fullPath}
              src2={slide.image.fullPath2x}
              src3={slide.image.fullPath3x}
            />
            <h2>{slide.title}</h2>
            <p>{slide.descriptionDesktop}</p>
          </div>
        </Conditional>
      </>
    );
  }
);
