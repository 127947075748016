import { bannerEnduranceImages } from '@/assets';
import ResponsiveLazyImage from '@/components/responsive-lazy-image';
import SectionElement from '@/components/section-element';
import { Context } from '@/context';
import { dataMenuLabels } from '@/data/menu.data';
import { FC, useContext } from 'react';
import styles from './Banner.module.scss';
import { bannerData } from '@/data/banner-endurance.data';
import { NewCta } from '@/components/new-cta';
import DataLayer from "@/utils/DataLayer";

const reference = dataMenuLabels().find(c => c.slug === "banner-endurance");

export const BannerSectionEndurance: FC = () => {
  const { state } = useContext(Context);
  const layerEvent = () => {
    DataLayer.clickEvent({
      elementCategory: "cta",
      element: 'saiba mais',
      pageSection: "banner-endurance",
      pageSubsection: `argo-banner`,
    });
  };
  return (
    <SectionElement
      id="BannerEndurance"
      className={styles.container}
      navReference={reference}
      noPadding
    >
      <div className={styles.bannerContainer}>
        {state.layout.isDesktop && (
          <div className={styles.bannerImage}>
            <ResponsiveLazyImage
              alt="Banner image"
              title="Banner image"
              src={bannerEnduranceImages.banner}
              src2={bannerEnduranceImages.banner2x}
              src3={bannerEnduranceImages.banner3x}
              style={{
                flex: 2,
              }}
            />
          </div>
        )}
        <div className={styles.bannerDescription}>
          <div>
            {bannerData.title}
            {!state.layout.isDesktop && (
              <div className={styles.bannerImage}>
                <ResponsiveLazyImage
                  alt="Banner image"
                  title="Banner image"
                  src={bannerEnduranceImages.bannerMobile}
                  src2={bannerEnduranceImages.bannerMobile2x}
                  src3={bannerEnduranceImages.bannerMobile3x}
                  style={{
                    flex: 1,
                  }}
                />
              </div>
            )}
            {bannerData.description}
            <a
              target="_blank"
              href={"https://www.fiat.com.br/argo-endurance.html"}
              key={`banner-cta`}
            >
              <NewCta
                width={"60%"}
                label={"Saiba mais"}
                handleClick={() => layerEvent()}
              />
            </a>
          </div>
        </div>
      </div>
    </SectionElement>
  );
};
