import { SectionElement } from "@/components"
import { dataMenuLabels } from "@/data/menu.data"
import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import Version, { cars }  from "./version"
import styles from "./version/version.module.scss"
import { Context } from "@/context"

const reference = dataMenuLabels().find((c) => c.slug === "versoes")

export const VersionsSectionV3: FC = observer(() => {
  const { state, dispatch } = useContext(Context)

  return (
    <SectionElement
      id="versions"
      noPadding
      navReference={reference}
      className={styles.main}
      onVisibilityChange={(load) => {
        const index = cars.findIndex(
          (c) => c.slug === state.layout.currentVersion.slug
        )

        if (load) {
          const suffix = cars[index ?? 0]?.slug
          setTimeout(() => {
            if (reference && state.layout.currentTab && suffix) {
              dispatch({
                type: "SET_CURRENTLY_VISIBLE_NAV",
                payload: {
                  view: reference,
                  suffix: suffix,
                },
              })
            }
          }, 100)
        }
      }}
    >
      <Version />
    </SectionElement>
  )
})
