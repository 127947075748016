import { SectionElement } from "@/components";
import { dataMenuLabels } from "@/data/menu.data";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import VersionsSwiper from "./versions-swiper";
import { dataVersions } from "@/data/versions.data";
import { Context } from "@/context";

const reference = dataMenuLabels().find((c) => c.slug === "versoes");

export const VersionsSectionV1: FC = observer(() => {
  const { state, dispatch } = useContext(Context);

  return (
    <SectionElement
      id="versoes"
      navReference={reference}
      noPadding
      onVisibilityChange={(load) => {
        const index = dataVersions.findIndex(
          (v) => v.slug === state.layout.currentVersion.slug
        );
        if (load) {
          const suffix = dataVersions[index ?? 0]?.slug;
          setTimeout(() => {
            if (reference && state.layout.currentTab && suffix) {
              dispatch({
                type: "SET_CURRENTLY_VISIBLE_NAV",
                payload: {
                  view: reference,
                  suffix: suffix,
                },
              });
            }
          }, 100);
        }
      }}
    >
      <VersionsSwiper />
    </SectionElement>
  );
});
