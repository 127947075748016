export const bannerData = {
  title: (
    <h2>
      MOTOR 1.3 <span>FIREFLY</span>
    </h2>
  ),
  description: (
    <>
      <h3>
        Eficiência e desempenho.<br />
        Isso é Fiat Argo. </h3>
      <p>
        <br />Experimente uma condução prazerosa de <br />verdade com o <strong>Motor 1.3 Firefly com 107 cv</strong><br /> de potência. Ele é o sonho de todo motorista que <br />busca desempenho aliado ao melhor consumo de <br />combustível.
      </p>
    </>
  ),
};
