import { versionsThumbs } from "@/assets";
import {
  ArrowButton,
  Conditional,
  Image,
  ResponsiveLazyImage,
  VersionsOrnament,
} from "@/components";
import { dataVersions } from "@/data/versions.data";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { VersionSlide } from "../version-slide";
import styles from "./versions-swiper.module.scss";
import { Context } from "@/context";
import {dataMenuLabels} from "@/data/menu.data";
import DataLayer from "@/utils/DataLayer";

SwiperCore.use([A11y, Keyboard, Thumbs]);
const reference = dataMenuLabels().find((c) => c.slug === "versoes");
const pageSubsection = "versoes"

const VersionsSwiper: FC = observer(() => {
  const [swiperController, setSwiperController] = useState<SwiperCore>();
  const [thumbSwiper, setThumbSwiper] = useState<SwiperCore>();
  const [, setTouchLocation] = useState<PointerEvent["clientX"]>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadAll] = useState(false);
  const {state, dispatch} = useContext(Context);
  const [initialIndex, setInitialIndex] = useState<number>(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const [load, setLoad] = useState(false);


    const previous = () => {
    if (currentIndex === 0) {
      swiperController?.slideTo(dataVersions.length - 1);
    } else {
      swiperController?.slidePrev();
    }
  };

  const next = () => {
    if (currentIndex >= dataVersions.length - 1) {
      swiperController?.slideTo(0);
    } else {
      swiperController?.slideNext();
    }
  };

  const Buttons = useCallback(
    () =>
      dataVersions.map((version, index) => {
        return (
          <button
            key={`thumb-${version.id}`}
            title={version.name}
            onClick={() => {
              DataLayer.clickEvent({
                element: version.name,
                elementCategory: "botao",
                pageSection: "conteudo",
                pageSubsection,
              });

              swiperController?.slideTo(index);
            }}
          >
            <div
              className={scssStyles([
                styles.thumbContainer,
                currentIndex === index ? styles.active : "",
              ])}
            >
              <div className={styles.thumbImage}>
                <ResponsiveLazyImage
                  alt={version.fullName}
                  src={versionsThumbs[version.id].fullPath}
                />
              </div>
              <p>
                <span className={styles.name}>{version.short}</span>
              </p>
              <div className={styles.thumbBackground} />
            </div>
          </button>
        );
      }),
    [currentIndex, swiperController]
  );

  useEffect(() => {
    if (
      thumbSwiper &&
      swiperController &&
      typeof thumbSwiper?.activeIndex === "number" &&
      typeof swiperController?.activeIndex === "number"
    ) {
      thumbSwiper.slideTo(swiperController.activeIndex);
    }
  }, [
    thumbSwiper,
    thumbSwiper?.activeIndex,
    swiperController,
    swiperController?.activeIndex,
  ]);

    useEffect(() => {
        setTimeout(() => {
            const index = dataVersions.findIndex((c) => c.slug === state.layout.currentVersion.slug)
            if (index) {
                setInitialIndex(index);
                swiperController?.slideTo(index);
            } else {
                setInitialIndex(1);
            }
        }, 100)


        if (state.layout.galleryExpanded) {
            swiperController?.keyboard.disable();
        } else {
            swiperController?.keyboard.enable();
        }

    }, []);

  return (
    <div className={styles.versionsContainer}>
      <h2 className={styles.versionsTitle}>
        Conheça
        <span>todas</span> <br />
        <span>as versões</span>
      </h2>
      <div
        className={scssStyles([
          styles.thumbs,
          state.layout.pageX <= 992 ? styles.mobile : "",
        ])}
      >
        {/* <Conditional condition={(state.layout.pageX ?? 0) > 992}> */}
        <Conditional condition={state.layout.pageX > 992}>
          {Buttons()}
        </Conditional>
        <Conditional condition={state.layout.pageX <= 992}>
          <Swiper
            observer
            observeParents
            parallax
            onSwiper={(e) => {
              setThumbSwiper(e);
            }}
            onSlideChange={(e) => {
                const selectedVersion = dataVersions[e.activeIndex];
                dispatch({
                    type: 'SET_CURRENT_VERSION',
                    payload: {
                        currentVersion: selectedVersion,
                    },
                });
                dispatch({
                    type: 'SET_CURRENTLY_VISIBLE_NAV',
                    payload: {
                        view: reference,
                        suffix: selectedVersion.slug,
                    },
                });
            }}
            controller={thumbSwiper ? { control: thumbSwiper } : undefined}
            centeredSlides
            initialSlide={initialIndex}
            // SLIDES PER VIEW:
            // Calculation based on window width minus padding
            // divided by the desired card (slide) width
            slidesPerView={Math.ceil((state.layout.pageX - 100) / 220)}
            spaceBetween={10}
          >
            {Buttons().map((button) => (
              <SwiperSlide
                key={`${button.key}-mobile`}
                className={styles.swiperSlideMobile}
              >
                {button}
              </SwiperSlide>
            ))}
          </Swiper>
        </Conditional>
      </div>
      <div className={styles.swiperWrapper}>
        <Swiper
          keyboard
          observer
          observeParents
          parallax
          thumbs={{ multipleActiveThumbs: true }}
          onSwiper={(e) => setSwiperController(e)}
          onSlideChange={async (swp) => {
              setTimeout(() => {
              setLoad(true);
                  const selectedVersion = dataVersions[swp.activeIndex];
                   dispatch({
                      type: 'SET_CURRENT_VERSION',
                      payload: {
                          currentVersion: selectedVersion,
                      },
                  });
                  setCurrentIndex(swp.activeIndex);
                   dispatch({
                      type: 'SET_CURRENTLY_VISIBLE_NAV',
                      payload: {
                          view: reference,
                          suffix: selectedVersion.slug,
                      },
                  });
                  setLoad(false);
              }, 100)

          }}
          initialSlide={initialIndex}
          onTouchStart={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            setTouchLocation(clientX);
          }}
          controller={
            swiperController ? { control: swiperController } : undefined
          }
        >
          {dataVersions.map((version) => (
            <SwiperSlide
              key={`versions-view-slide-${version.id}`}
              className={styles.slidePadding}
            >
              <VersionSlide load={loadAll} version={version} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Conditional notOn="mobile">
          <ArrowButton
            previous
            disabled={load}
            className={styles.previous}
            handleClick={() => {
              DataLayer.clickEvent({
                element: 'anterior',
                elementCategory: 'icone',
                pageSection: 'conteudo',
                pageSubsection,
              });

              previous();
            }}
          />
          <ArrowButton
              disabled={load}
            className={styles.next}
            handleClick={() => {
              DataLayer.clickEvent({
                element: 'proximo',
                elementCategory: 'icone',
                pageSection: 'conteudo',
                pageSubsection,
              });
              next();
            }}
          />
        </Conditional>
      </div>
    </div>
  );
});

export default VersionsSwiper;
