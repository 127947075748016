import { SectionElement } from "@/components";
import { dataMenuLabels } from "@/data/menu.data";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import PresentationSwiperComponent from "./presentation-swiper";

const reference = dataMenuLabels().find((c) => c.slug === "apresentacao");

export const PresentationSections: FC = observer(() => {
  return (
    <SectionElement
      id="ConnectMe"
      navReference={reference}
    >
      <PresentationSwiperComponent />
    </SectionElement>
  );
});
