import { SectionElement, Stories } from "@components/index"
import { FC } from "react"
import styles from "./stories-section.module.scss"
import { observer } from "mobx-react-lite"
import { dataMenuLabels } from "@/data/menu.data"
import { dataHero, IHero } from "@/data/hero.data"

const MainStories: FC = observer(() => {
  const items: IHero[] | any = []
  dataHero.map((item, index) =>
    items.push({
      showNext: true,
      body: (
        <>
          <div className={styles.slides}>
            {index != 0 && (
              <div className={styles.slidesContainer}>
                <div className={styles.bottomBox}>
                  {index > 0 && <h2>{item.titleStories}</h2>}
                  <p className={styles.contentText}>
                    {item.descriptionStories}
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      ),
      scrollCtaOrientation: "vertical",
      duratiocnInS: 15,
      ...item,
    })
  )
  
  return (
    <SectionElement
      id="mobileStories"
      navReference={dataMenuLabels()[0]}
      className={styles.container}
      noPadding
    >
      <Stories items={items} />
    </SectionElement>
  )
})

export default MainStories
