import { Conditional, SectionElement } from "@/components";
import React360 from "@/components/react-360";
import { Images360 } from "@/data/internal-view-data";
import { dataMenuLabels } from "@/data/menu.data";
import { useImagePreloader } from "@/hooks";
import scssStyles from "@/utils/scssStyles";
import { FC, useEffect, useState } from "react";
import styles from "./internal-view-section.module.scss";

const reference = dataMenuLabels().find((c) => c.slug === "espaco-interno");

export const InternalViewSection: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const imageValue = Images360();

  const { imagesPreloaded } = useImagePreloader(imageValue);
  const [show360, setShow360] = useState<boolean>(false);
  const [internalView, setInternalView] = useState<boolean>(false);

  useEffect(() => {
    if (imagesPreloaded) {
      setInternalView(true);
    }
  }, [imagesPreloaded]);

  const handleCloseClick = () => {
    setIsActive(false);
  };

  return (
    <>
      <SectionElement
        id="InternalView"
        className={`${styles.container} ${
          isActive ? styles.containerActive : ""
        }`}
        navReference={reference}
      >
        <>
          <Conditional notOn={"desktop"}>
            <div className={styles.backgroundImage}>
              <img
                alt={imageValue[0].accessibility?.alt}
                src={imageValue[0].src}
              />
            </div>
          </Conditional>

          <Conditional notOn="mobile">
            <div className={styles.backgroundImage}>
              <img
                alt={imageValue[0].accessibility?.alt}
                src={imageValue[0].src}
              />
            </div>
          </Conditional>

          <div className={styles.containerBox}>
            <div
              className={scssStyles(["bringToFront", styles.spacer])}
              id={show360 ? styles.active : ""}
            >
              {!show360 ? (
                <div className={styles.versionText}>
                  <div className={styles.contentArea}>
                    <h2>
                      Lorem <br />
                      ipsum dolor
                    </h2>
                  </div>
                  <div className={styles.iconArea}>
                    {!internalView ? (
                      <>
                        <div className={styles.spinner}>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <h3>CARREGANDO 360</h3>
                      </>
                    ) : (
                      <>
                        <h3
                          style={{ cursor: internalView ? "pointer" : "" }}
                          onClick={() => {
                            if (!internalView) {
                              return;
                            } else {
                              setShow360(true);
                            }
                          }}
                        >
                          INICIAR 360
                        </h3>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <React360 handleClose={handleCloseClick} />
              )}
            </div>
          </div>
        </>
      </SectionElement>
    </>
  );
};
