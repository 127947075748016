export const bannerData = {
  title: (
    <h2>
      ARGO <span>ENDURANCE</span>
    </h2>
  ),
  description: (
    <>
      <h3>VERSATILIDADE PARA SUAS NECESSIDADES</h3>
      <p>
        Garanta seu melhor aliado para o trabalho, <br />
        seja na terra ou no asfalto.
      </p>
    </>
  ),
};
