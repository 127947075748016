import { CTAButton, Conditional, ResponsiveLazyImage } from "@/components";
import { dataSocial } from "@/data/footer.data";
import { dataMenuButtons } from "@/data/menu.data";
import { FC } from "react";
import styles from "./footer.module.scss";
import DataLayer from "@/utils/DataLayer";

export const Footer: FC = () => {
  return (
    <footer className={styles.container}>
      {!!dataMenuButtons.length && (
        <Conditional notOn="desktop">
          <div className={styles.conditional}>
            <div className={styles.buttonsHolder}>
              {dataMenuButtons.map((item) => {
                const tracking = () => {
                  DataLayer.clickEvent({
                    element: item.label,
                    elementCategory: "cta",
                    pageSection: "footer",
                    pageSubsection: "ctas",
                  });
                };
                return (
                  <CTAButton
                    key={`footer-${item.id}`}
                    href={item.url}
                    className={styles.menuButton}
                    text={item.label}
                    title={item.label}
                    variant="quaternary"
                    handleAuxClick={() => tracking()}
                    handleClick={() => tracking()}
                  />
                );
              })}
            </div>
          </div>
        </Conditional>
      )}
      <div className={styles.social}>
        <div>
          {dataSocial.map((social) => {
            const tracking = () => {
              DataLayer.clickEvent({
                element: social.title,
                elementCategory: "icone",
                pageSection: "footer",
                pageSubsection: "social",
              });
            };
            return (
              <a
                key={social.id}
                target="_blank"
                href={social.url}
                title={social.title}
                onClick={() => {
                  tracking();
                }}
                onAuxClick={() => {
                  tracking();
                }}
              >
                <ResponsiveLazyImage
                  className={styles.icons}
                  src={social.icon}
                  alt={social.title}
                />
              </a>
            );
          })}
        </div>
        <p className={styles.description}>
          A disponibilidade de itens de série, opcionais e acessórios pode
          variar de acordo com a versão escolhida. Verifique o Monte seu Carro.
          Imagens meramente ilustrativas.
        </p>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="54"
        viewBox="0 0 102 54"
      >
        <defs>
          <clipPath id="clip-path">
            <rect id="mask" width="102" height="54" fill="#0aff00" />
          </clipPath>
        </defs>
        <g id="nome-feature" clipPath="url(#clip-path)">
          <g id="Fiat_Flag" data-name="Fiat Flag" transform="translate(6.674)">
            <path
              id="Caminho_149"
              data-name="Caminho 149"
              d="M518.08,300.235l-21.415,66h9.913l21.415-66Z"
              transform="translate(-476.836 -300.235)"
              fill="#fdb72b"
            />
            <path
              id="Caminho_150"
              data-name="Caminho 150"
              d="M337.984,300.235l-21.415,66h9.912l21.415-66Z"
              transform="translate(-316.569 -300.235)"
              fill="#fdb72b"
            />
            <path
              id="Caminho_151"
              data-name="Caminho 151"
              d="M698.175,300.235l-21.415,66h9.913l21.415-66Z"
              transform="translate(-637.106 -300.235)"
              fill="#fdb72b"
            />
            <path
              id="Caminho_152"
              data-name="Caminho 152"
              d="M878.271,300.235l-21.415,66h9.913l21.415-66Z"
              transform="translate(-797.375 -300.235)"
              fill="#fdb72b"
            />
          </g>
        </g>
      </svg>
    </footer>
  );
};
