import { FC } from "react"
import styles from "./SectionTitle.module.scss"

interface SectionTitle {
  title: string
  strong: string
}

const SectionTitle: FC<SectionTitle> = ({ title, strong }) => {
  return (
      <h2 className={styles.title}>
        {title} <span>{strong}</span>
      </h2>
  )
}
export default SectionTitle
