import { ArrowButton } from "@/components";
import { Context } from "@/context/context";
import { PresentationSectionProps } from "@/data/presentation-section.data";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import styles from "./presentation-swiper-controller.module.scss";

SwiperCore.use([A11y, Keyboard, Thumbs]);

export const PresentationSwiperController: FC<{
  data: PresentationSectionProps[];
}> = observer(({ data }) => {
  const { state, dispatch } = useContext(Context);
  const [, setPresensationSwiperIndex] = useState<number | undefined>(0);

  const handleSlidePrev = () => {
    // Datalayer
    state.layout.presentationSwiperController?.slidePrev();
    setPresensationSwiperIndex(
      state.layout.presentationSwiperController?.activeIndex ?? 0
    );
  };

  const handleSlideNext = () => {
    // Datalayer
    state.layout.presentationSwiperController?.slideNext();
    setPresensationSwiperIndex(
      state.layout.presentationSwiperController?.activeIndex ?? 0
    );
  };

  const nextSlide = !state.layout.presentationSwiperController
    ? undefined
    : data[state.layout.presentationSwiperController.activeIndex + 1];
  return (
    <div className={styles.controls}>
      <div className={styles.bullets}>
        {data.map((c, index) => {
          return (
            <button
              key={`bullet-${c.id}`}
              title={c.title}
              className={scssStyles([
                styles.bullet,
                state.layout.presentationSwiperController?.activeIndex === index
                  ? styles.active
                  : "",
              ])}
              onClick={() => {
                // Datalayer
                state.layout.presentationSwiperController?.slideTo(index);
              }}
            >
              <span />
            </button>
          );
        })}
      </div>
      <div className={styles.arrows}>
        <ArrowButton
          previous
          light
          large
          disabled={
            state.layout.presentationSwiperController?.activeIndex === 0
          }
          handleClick={handleSlidePrev}
        />
        <ArrowButton
          light
          large
          disabled={
            (state.layout.presentationSwiperController?.activeIndex ?? 0) >=
            data.length - 1
          }
          handleClick={handleSlideNext}
        />
        {!!nextSlide?.title && (
          <div className={styles.nextTab}>
            <strong>Próximo</strong>
            <p>{nextSlide?.title}</p>
          </div>
        )}
      </div>
    </div>
  );
});
