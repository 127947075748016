import { Context } from "@/context/context";
import { PresentationSectionData } from "@/data/presentation-section.data";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PresentationSlideComponent } from "../presentation-slide";
import styles from "./presentation-swiper-component.module.scss";
import { PresentationSwiperController } from "../presentation-swiper-controller";

SwiperCore.use([A11y, Keyboard, Thumbs]);

const PresentationSwiperComponent: FC = observer(() => {
  const { state, dispatch } = useContext(Context);

  const [, rerender] = useState(false);
  const [, setTouchLocation] = useState<PointerEvent["clientX"]>();

  return (
    <>
      <div className={styles.container}>
        <Swiper
          onSwiper={async (e) =>
            dispatch({
              type: "SET_PRESENTATION_CONTROLLER",
              payload: { presentationSwiperController: e },
            })
          }
          controller={
            state.layout.presentationSwiperController
              ? { control: state.layout.presentationSwiperController }
              : undefined
          }
          spaceBetween={100}
          className={styles.swiperSlide}
          observer
          observeParents
          parallax
          onSlideChange={() => {
            rerender((r) => !r);
          }}
          onTouchStart={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            setTouchLocation(clientX);
          }}
        >
          {PresentationSectionData.map((slide) => (
            <SwiperSlide key={`$${slide.id}`}>
              <PresentationSlideComponent slide={slide} />
            </SwiperSlide>
          ))}
        </Swiper>

        <PresentationSwiperController data={PresentationSectionData} />
      </div>
    </>
  );
});

export default PresentationSwiperComponent;
