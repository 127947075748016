import { links, linksLabel } from "./menu.data";

export const dealData = {
  ctas: [
    {
      label: linksLabel.financiamento,
      href: links.financiamento,
      slug: "simule-um-fincanciamento",
    },
    {
      label: linksLabel.concessionaria,
      href: links.concessionarias,
      slug: "concessionaria",
    },
    { label: linksLabel.ofertas, href: links.ofertas, slug: "ofertas-fiat" },
  ],
  title: (
    <h2>
      VAMOS <em>NEGOCIAR?</em>
    </h2>
  ),
};
