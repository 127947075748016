import {
  connectMe as connectMeAssets,
  connectMeBackgrounds,
} from "@/assets/connectMe";
import { IncludePrefixResponsiveSizes } from "@/utils/imagePrefixes";

export interface PresentationSectionProps {
  id: string;
  title: string;
  titleElement?: JSX.Element;
  descriptionDesktop: JSX.Element;
  descriptionMobile: JSX.Element;
  image: IncludePrefixResponsiveSizes;
  background: IncludePrefixResponsiveSizes;
}

export const PresentationSectionData: PresentationSectionProps[] = [
  {
    id: "cme-c-1",
    title: "Presentation Data 1",
    titleElement: <></>,
    descriptionDesktop: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos est eius
        magni inventore consequuntur, accusantium quas blanditiis nam illum quia
        fuga nemo eos et asperiores repellendus in velit? Animi, deserunt.
      </p>
    ),
    descriptionMobile: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos est eius
        magni inventore consequuntur, accusantium quas blanditiis nam illum quia
        fuga nemo eos et asperiores repellendus in velit? Animi, deserunt.
      </p>
    ),
    image: connectMeAssets.d01,
    background: connectMeBackgrounds.d01,
  },
  {
    id: "cme-c-2",
    title: "Presentation 2",
    titleElement: <>{"Operações remotas"}</>,
    descriptionDesktop: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos est eius
        magni inventore consequuntur, accusantium quas blanditiis nam illum quia
        fuga nemo eos et asperiores repellendus in velit? Animi, deserunt.
      </p>
    ),
    descriptionMobile: (
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos est eius
        magni inventore consequuntur, accusantium quas blanditiis nam illum quia
        fuga nemo eos et asperiores repellendus in velit? Animi, deserunt.
      </p>
    ),
    image: connectMeAssets.d02,
    background: connectMeBackgrounds.d02,
  },
];
