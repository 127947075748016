import { ArrowButton, ResponsiveLazyImage, VimeoEmbed } from "@/components";
import { Context } from "@/context";
import productDetails, {
  allSlides,
  ProductTab,
  ProductTabSlide,
} from "@/data/product-details.data";
import DataLayer from "@/utils/DataLayer";
import getClientXFromDifferentEvents from "@/utils/getClientXFromDifferentEvents";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./swiper-product-details-desktop.module.scss";

SwiperCore.use([A11y, Keyboard, Thumbs]);

interface SlideProps {
  slide: ProductTabSlide;
  tab: ProductTab;
  productDetailsVersion?: 1 | 2;
}

interface SwiperProps {
  productDetailsVersion?: 1 | 2;
  setReference: (tab: any) => void;
}

const pageSection = "tudo-sobre";

const Slide: FC<SlideProps> = observer(
  ({ slide, tab, productDetailsVersion }) => {
    return (
      <div className={styles.slideContainer}>
        {(() => {
          if (slide.vimeoId?.desktop) {
            return (
              <div className={styles.videoWrapper}>
                <VimeoEmbed id={slide.vimeoId.desktop} title={slide.titleSEO} />
              </div>
            );
          } else if (productDetailsVersion === 2) {
            return null;
          } else {
            return (
              <ResponsiveLazyImage
                alt={slide.titleSEO}
                title={slide.titleSEO}
                src={slide.image.fullPath}
                src2={slide.image.fullPath2x}
                src3={slide.image.fullPath3x}
                containerClassName={styles.slideImageContainer}
                className={styles.slideImage}
              />
            );
          }
        })()}
        <div className={styles.slideDetails}>
          <h3>{tab.title}</h3>
          <h4>{slide.title}</h4>
          <div className={styles.slideDescription}>
            {slide.descriptionDesktop}
          </div>
        </div>
      </div>
    );
  }
);

const SwipersDesktop: FC<SwiperProps> = observer(
  ({ productDetailsVersion, setReference }) => {
    const { state, dispatch } = useContext(Context);
    const [, rerender] = useState(false);
    const [touchLocation, setTouchLocation] =
      useState<PointerEvent["clientX"]>();
    const nextSlide = !state.layout.productDetailsSwiperController
      ? undefined
      : allSlides[state.layout.productDetailsSwiperController.activeIndex + 1];
    const previousSlide = !state.layout.productDetailsSwiperController
      ? undefined
      : allSlides[state.layout.productDetailsSwiperController.activeIndex - 1];

    const handleSwiperChange = async (tab: ProductTab) => {
      await dispatch({
        type: "SET_CURRENTLY_VISIBLE_NAV",
        payload: {
          tab: tab,
        },
      });

      await dispatch({
        type: "SET_DETAIL_TAB",
        payload: {
          selectedDetailTab: tab,
        },
      });
    };
    return (
      <div className={styles.container}>
        <Swiper
          observer
          observeParents
          parallax
          onSwiper={(e) =>
            dispatch({
              type: "SET_PRODUCT_DETAILS_CONTROLLER",
              payload: { productDetailsSwiperController: e },
            })
          }
          controller={
            state.layout.productDetailsSwiperController
              ? { control: state.layout.productDetailsSwiperController }
              : undefined
          }
          spaceBetween={100}
          onSlideChange={(swiper) => {
            const index = swiper.activeIndex;
            const tab = productDetails.find(
              (p) => p.id === allSlides[index].tabId
            );
            if (tab) {
              handleSwiperChange(tab);
              setReference(tab);
            }
            rerender((r) => !r);
          }}
          onTouchStart={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            setTouchLocation(clientX);
          }}
          onTouchEnd={(_, event) => {
            const clientX = getClientXFromDifferentEvents(event);
            DataLayer.swipeEvent({
              element: (touchLocation ?? 0) > clientX ? "proximo" : "anterior",
              elementCategory: "imagem",
              pageSection,
              pageSubsection:
                ((touchLocation ?? 0) > clientX ? nextSlide : previousSlide)
                  ?.parentSlug ??
                productDetails.find(
                  (c) => c.id === state.layout.selectedDetailTab
                )?.slug ??
                "",
            });
          }}
        >
          {productDetails.map((tab) =>
            tab.children.map((slide) => (
              <SwiperSlide key={`detail-${tab.id}-${slide.id}`}>
                <Slide
                  tab={tab}
                  slide={{ ...slide, tabId: tab.id }}
                  productDetailsVersion={productDetailsVersion}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
        <div className={styles.controls}>
          {state.layout.pageX >= 1200 && (
            <div className={styles.bullets}>
              {allSlides
                .filter((t) => t.tabId === state.layout.currentTab?.id)
                .map((c, index) => {
                  return (
                    <button
                      key={`bullet-sw-${c.id}`}
                      title={c.titleSEO}
                      className={scssStyles([
                        styles.bullet,
                        state.layout.productDetailsSwiperController
                          ?.activeIndex === c.index
                          ? styles.active
                          : "",
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: 'item-'+ index,
                          elementCategory: "icone",
                          pageSection,
                          pageSubsection: c.tabId ?? "",
                        })
                        if (
                          c.index &&
                          state.layout.productDetailsSwiperController
                        ) {
                          state.layout.productDetailsSwiperController?.slideTo(
                            c.index
                          );
                        }
                      }}
                    >
                      <span />
                    </button>
                  );
                })}
            </div>
          )}
          <div className={styles.arrows}>
            <ArrowButton
              previous
              large
              disabled={
                (state.layout.productDetailsSwiperController?.activeIndex ??
                  0) -
                  1 <
                0
              }
              handleClick={() => {
                const pageSubsection:any = state.layout.selectedDetailTab
                DataLayer.clickEvent({
                  element: "anterior",
                  elementCategory: "icone",
                  pageSection,
                  pageSubsection,
                });
                state.layout.productDetailsSwiperController?.slidePrev();
              }}
            />
            <ArrowButton
              large
              disabled={
                (state.layout.productDetailsSwiperController?.activeIndex ??
                  allSlides.length) >=
                allSlides.length - 1
              }
              handleClick={() => {
                const pageSubsection:any = state.layout.selectedDetailTab
                DataLayer.clickEvent({
                  element: "proximo",
                  elementCategory: "icone",
                  pageSection,
                  pageSubsection,
                });
                state.layout.productDetailsSwiperController?.slideNext();
              }}
            />
            {!!nextSlide?.title && (
              <div className={styles.nextTab}>
                <strong>Próximo</strong>
                <p style={{ textTransform: "uppercase" }}>{nextSlide?.title}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default SwipersDesktop;
