import { Conditional, SectionElement } from "@/components";
import { metaTags } from "@/constants";
import { PRODUCT_DETAILS_REF, dataMenuLabels } from "@/data/menu.data";
import { FC, useContext } from "react";
import Tabs from "./product-details-tabs-component";
import styles from "./product-details-section.module.scss";
import SwipersDesktop from "./product-details-swiper-component/swiper-product-details-desktop.component";
import SwipersMobile from "./product-details-swiper-component/swiper-product-details-mobile.component";
import { Context } from "@/context";
import productDetails, {
  allSlides,
  getProductDetails,
  getProductDetailsv2,
} from "@/data/product-details.data";

const reference = dataMenuLabels().find((c) => c.slug === "tudo-sobre-argo");

export const ProductDetails: FC = () => {
  const { state, dispatch } = useContext(Context);

  const setReference = (tab?: any) => {
    let reference;
    let suffix;
    const index = !state.layout.isDesktop
      ? state.layout.mobileSwiperController[
          state.layout.selectedDetailTab?.toString() ?? 0
        ]?.activeIndex
      : state.layout.productDetailsSwiperController?.activeIndex;

    if (tab) {
      reference = getProductDetailsv2(productDetails).find(
        (p) => p.id === `data-${tab?.id}`
      );
      if (!state.layout.isDesktop) {
        suffix = tab.children[index ?? 0].slug;

      } else {
        suffix = allSlides[index ?? 0].slug;
      }
    } else {
      suffix = allSlides[index ?? 0].slug;
      reference = getProductDetailsv2(productDetails).find(
        (p) => p.id === `data-${state.layout.currentTab?.id}`
      );
    }


    if (reference) {
      if (state.layout.selectedDetailTab) {
        dispatch({
          type: "SET_CURRENTLY_VISIBLE_NAV",
          payload: { view: reference, suffix: suffix },
        });
      }
    }
  };

  return (
    <SectionElement
      id="ProductDetails"
      navReference={reference}
      className={styles.container}
      slugPrefixes={productDetails.map(p => p.slug)}
      onSlugSuffix={(slug) => {
        if (slug) {
          const slide = allSlides.find((s) => s.slug === slug);
          const index = allSlides.findIndex((s) => s.slug === slug);
          if (slide && state.layout.productDetailsSwiperController?.params) {
            state.layout.productDetailsSwiperController.slideTo(
                index >= 0 ? index : 0
            );
          }
          if (slide && state.layout.productDetailsMobileSwiperController?.params) {
          }
        }
      }}
      onVisibilityChange={(visible) => {
        if (visible) {
          setTimeout(() => {
            setReference();

          }, 100)
        }
      }}

    >
      <Conditional notOn="mobile">
        <div className={styles.white}></div>
      </Conditional>

      <p className={styles.sectionName}>
        <Conditional notOn="desktop">
          Tudo sobre o <br /> <span>Fiat argo</span>
        </Conditional>
        <Conditional notOn="mobile">
          Tudo sobre o <span>Fiat argo</span>
        </Conditional>
      </p>
      <Conditional
        desktop={
          <>
            <Tabs />
            <SwipersDesktop setReference={setReference} />
          </>
        }
        mobile={<SwipersMobile setReference={setReference} />}
      />
    </SectionElement>
  );
};
