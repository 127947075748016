import { gallery, galleryThumbs } from "@/assets/gallery";
import {
  ArrowButton,
  Conditional,
  IconButton,
  ResponsiveLazyImage,
  SectionElement,
  VimeoEmbed,
} from "@/components";
import { Magnifying } from "@/components/svg-components";
import { Context } from "@/context";
import { dataMenuLabelDesktop, dataMenuLabels } from "@/data/menu.data";
import getRangeOfEntries from "@/utils/getRangeOfEntries";
import scssStyles from "@/utils/scssStyles";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import SwiperCore, { A11y, Keyboard, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ExpandedGallery from "./expanded-gallery";
import styles from "./gallery-section.module.scss";
import DataLayer from "@/utils/DataLayer";

SwiperCore.use([A11y, Keyboard, Thumbs]);

const reference = dataMenuLabels().find((c) => c.slug === "fotos");

export const GalleryV1: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [controller, setController] = useState<SwiperCore>();
  const pageSubsection = "galeria";

  const findIndex = () => {
    return gallery.findIndex(
      (g) => g.id === state.layout.selectedGalleryImage?.id
    );
  };

  const updateIndex = useCallback(
    (n: number) => {
      dispatch({
        type: "SET_GALLERY_IMAGE",
        payload: { selectedGalleryImage: gallery[n] },
      });
      if (controller && typeof n === "number") {
        controller?.slideTo(n);
      }
    },
    [controller, dispatch]
  );

  const next = () => {
    const index = findIndex();
    const nIndex = index + 1 > gallery.length - 1 ? 0 : index + 1;
    updateIndex(nIndex);
  };
  const previous = () => {
    const index = findIndex();
    const nIndex = index - 1 < 0 ? gallery.length - 1 : index - 1;
    updateIndex(nIndex);
  };

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => {
      DataLayer.swipeEvent({
        element: "anterior",
        elementCategory: "imagem",
        pageSection: "conteudo",
        pageSubsection,
      });

      next();
    },
    onSwipedRight: () => {
      DataLayer.swipeEvent({
        element: "proximo",
        elementCategory: "imagem",
        pageSection: "conteudo",
        pageSubsection,
      });

      previous();
    },
  });

  const bulletProps = getRangeOfEntries(findIndex(), gallery);

  return (
    <>
      <SectionElement
        id="Gallery"
        className={styles.container}
        navReference={reference}
      >
        <Conditional desktop={<div className={styles.white}></div>} />
        <h2 className={styles.sectionName}>
          <Conditional notOn="desktop">
            O SEU <span>FIAT ARGO</span><br />POR TODOS OS ÂNGULOS
          </Conditional>
          <Conditional notOn="mobile">
            O SEU <span>FIAT ARGO</span> POR TODOS OS ÂNGULOS
          </Conditional>
        </h2>
        <div className={styles.galleryContainer}>
          <div className={styles.aligner}>
            <div className={styles.mainImage}>
              {!state.layout.selectedGalleryImage?.video && (
                <IconButton
                  className={styles.expandIcon}
                  title="Expandir imagem"
                  icon={
                    <>
                      <Magnifying />
                    </>
                  }
                  handleClick={() => {
                    DataLayer.clickEvent({
                      element: `amplia-foto-${
                        gallery.findIndex(
                          (c) => c.id === state.layout.selectedGalleryImage.id
                        ) + 1
                      }`,
                      elementCategory: "icone",
                      pageSection: "conteudo",
                      pageSubsection,
                    });
                    dispatch({
                      type: "SET_GALLERY_EXPANDED",
                      payload: {
                        galleryExpanded: !state.layout.galleryExpanded,
                      },
                    });
                  }}
                />
              )}
              <AnimatePresence>
                {gallery.map((g) => {
                  const isMobile = !state.layout.isDesktop;
                  if (state.layout.selectedGalleryImage?.id !== g.id)
                    return null;
                  return (
                    <motion.div
                      key={`main-gallery-${g.id}`}
                      {...swipeHandlers}
                      initial={{
                        y: isMobile ? -100 : 0,
                        x: !isMobile ? 100 : 0,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        x: 0,
                        scale: 1,
                      }}
                      exit={{
                        opacity: 0,
                        y: isMobile ? 100 : 0,
                        x: !isMobile ? 100 : 0,
                      }}
                      transition={{
                        duration: 0.2,
                      }}
                    >
                      {!!g.video && !!g.video.desktop ? (
                        <VimeoEmbed
                          id={g.video.desktop}
                          className={styles.videoHolder}
                          title={g.title}
                        />
                      ) : (
                        <ResponsiveLazyImage
                          alt={g.alt}
                          src={g.asset.fullPath}
                          src2={g.asset.fullPath2x}
                          src3={g.asset.fullPath3x}
                        />
                      )}
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
            <Conditional
              mobile={
                <div className={styles.thumbs}>
                  <Swiper
                    observer
                    observeParents
                    parallax
                    spaceBetween={10}
                    slidesPerView={Math.ceil(state.layout.pageX / 150)}
                    onSwiper={(e) => setController(e)}
                    controller={
                      controller ? { control: controller } : undefined
                    }
                    className={styles.swiperMobile}
                  >
                    {galleryThumbs.map((p, index) => (
                      <SwiperSlide
                        key={`gallery-thumb-${p.id}`}
                        className={styles.swiperSlide}
                      >
                        <button
                          title={p.title}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${index + 1}`,
                              elementCategory: "imagem",
                              pageSection: "conteudo",
                              pageSubsection,
                            });
                            updateIndex(index);
                          }}
                        >
                          <img
                            className={scssStyles([
                              state.layout.selectedGalleryImage?.id === p.id
                                ? styles.thumbActive
                                : "",
                            ])}
                            src={p.thumb}
                            alt={p.alt}
                          />
                        </button>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              }
              desktop={
                <div className={styles.thumbsDesktop}>
                  <div className={styles.rightSide}>
                    {galleryThumbs.slice(0, 4).map((p, index) => {
                      return (
                        <button
                          key={`galleryThumb-${p.id}`}
                          title={p.title}
                          onClick={() => {
                            DataLayer.clickEvent({
                              element: `foto-${index + 1}`,
                              elementCategory: "imagem",
                              pageSection: "conteudo",
                              pageSubsection,
                            });

                            dispatch({
                              type: "SET_GALLERY_IMAGE",
                              payload: { selectedGalleryImage: p },
                            });
                          }}
                          className={scssStyles([
                            state.layout.selectedGalleryImage?.id === p.id
                              ? styles.active
                              : "",
                          ])}
                        >
                          <img src={p.thumb} alt={p.alt} title={p.title} />
                          {state.layout.selectedGalleryImage?.id === p.id && (
                            <div className={styles.bar} />
                          )}
                        </button>
                      );
                    })}
                  </div>

                  <div className={styles.leftSide}>
                    {galleryThumbs.slice(-4).map((p) => (
                      <button
                        key={`galleryThumb-${p.id}`}
                        title={p.title}
                        onClick={() => {
                          DataLayer.clickEvent({
                            element: `foto-${findIndex() - 2}`,
                            elementCategory: "icone",
                            pageSection: "conteudo",
                            pageSubsection,
                          });

                          dispatch({
                            type: "SET_GALLERY_IMAGE",
                            payload: { selectedGalleryImage: p },
                          });
                        }}
                        className={scssStyles([
                          state.layout.selectedGalleryImage?.id === p.id
                            ? styles.active
                            : "",
                        ])}
                      >
                        <img src={p.thumb} alt={p.alt} title={p.title} />
                        {state.layout.selectedGalleryImage?.id === p.id && (
                          <div className={styles.bar} />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              }
            />
          </div>
          <Conditional notOn="desktop">
            <div className={styles.controls}>
              <ArrowButton
                previous
                color="#919191"
                className={styles.prevArrow}
                handleClick={() => {
                  DataLayer.clickEvent({
                    element: "anterior",
                    elementCategory: "icone",
                    pageSection: "conteudo",
                    pageSubsection,
                  });

                  previous();
                }}
              />
              <div className={styles.bullets}>
                {!!bulletProps.before &&
                  Array.from(Array(bulletProps.before).keys())
                    .slice(0, 2)
                    .map((_, i) => (
                      <button
                        key={`smaller-bullet-gallery-before-${i}`}
                        className={scssStyles([
                          styles.bullet,
                          i == 1 || bulletProps.before === 1
                            ? "mediumBullet"
                            : "smallerBullet",
                        ])}
                        onClick={() => {
                          DataLayer.clickEvent({
                            element: `foto-${findIndex() - 2}`,
                            elementCategory: "icone",
                            pageSection: "conteudo",
                            pageSubsection,
                          });

                          updateIndex(findIndex() - 2);
                        }}
                      >
                        <span />
                      </button>
                    ))}
                {gallery.map((c, index) => {
                  if (bulletProps.range.indexOf(index) === -1) return null;
                  return (
                    <button
                      key={`bullet-${c.id}`}
                      title={c.title}
                      className={scssStyles([
                        styles.bullet,
                        state.layout.selectedGalleryImage?.id === c.id
                          ? styles.active
                          : "",
                      ])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `foto-${index + 1}`,
                          elementCategory: "icone",
                          pageSection: "conteudo",
                          pageSubsection,
                        });

                        dispatch({
                          type: "SET_GALLERY_IMAGE",
                          payload: { selectedGalleryImage: c },
                        });
                      }}
                    >
                      <span />
                    </button>
                  );
                })}
                {!!bulletProps.after &&
                  Array.from(Array(bulletProps.after).keys())
                    .slice(0, 2)
                    .map((_, i) => (
                      <button
                        key={`smaller-bullet-gallery-after-${i}`}
                        className={scssStyles([
                          styles.bullet,
                          i == 0 || bulletProps.after === 1
                            ? "mediumBullet"
                            : "smallerBullet",
                        ])}
                        onClick={() => {
                          DataLayer.clickEvent({
                            element: `foto-${findIndex() + 2}`,
                            elementCategory: "icone",
                            pageSection: "conteudo",
                            pageSubsection,
                          });

                          updateIndex(findIndex() + 2);
                        }}
                      >
                        <span />
                      </button>
                    ))}
              </div>
              <ArrowButton
                color="#919191"
                className={styles.nextArrow}
                handleClick={() => {
                  DataLayer.clickEvent({
                    element: "proximo",
                    elementCategory: "icone",
                    pageSection: "conteudo",
                    pageSubsection,
                  });

                  next();
                }}
              />
            </div>
          </Conditional>
        </div>
      </SectionElement>

      <AnimatePresence>
        {state.layout.galleryExpanded && <ExpandedGallery />}
      </AnimatePresence>
    </>
  );
});
