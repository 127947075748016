import { bannerImages } from '@/assets';
import ResponsiveLazyImage from '@/components/responsive-lazy-image';
import SectionElement from '@/components/section-element';
import { Context } from '@/context';
import { dataMenuLabels } from '@/data/menu.data';
import { FC, useContext } from 'react';
import styles from './Banner.module.scss';
import { bannerData } from '@/data/banner.data';

const reference = dataMenuLabels().find((c) => c.slug === 'banner');

export const BannerSection: FC = () => {
  const { state } = useContext(Context);

  return (
    <SectionElement
      id="Banner"
      className={styles.container}
      navReference={reference}
      noPadding
    >
      <div className={styles.bannerContainer}>
        <div className={styles.bannerDescription}>
          <div>
            {bannerData.title}
            {!state.layout.isDesktop && (
              <div className={styles.bannerImage}>
                <ResponsiveLazyImage
                  alt="Banner image"
                  title="Banner image"
                  src={bannerImages.bannerMobile}
                  src2={bannerImages.bannerMobile2x}
                  src3={bannerImages.bannerMobile3x}
                  style={{
                    flex: 1,
                  }}
                />
              </div>
            )}
            {bannerData.description}
          </div>
        </div>
        {state.layout.isDesktop && (
          <div className={styles.bannerImage}>
            <ResponsiveLazyImage
              alt="Banner image"
              title="Banner image"
              src={bannerImages.banner}
              src2={bannerImages.banner2x}
              src3={bannerImages.banner3x}
              style={{
                flex: 2,
              }}
            />
          </div>
        )}
      </div>
    </SectionElement>
  );
};
