import { Context } from "@/context";
import { Images360 } from "@/data/internal-view-data";
import { useImagePreloader } from "@/hooks";
import { React360Props, imageProps } from "@/models";
import React, { FC, useContext, useEffect, useState } from "react";
import ReactHammer from "react-hammerjs";
import styles from "./react-360.module.scss";

const pixelsPerDegree = 3;

const ImageComponent: FC<imageProps> = ({ src, alt }) => {
  const [srcImage, setSrcImage] = useState<string>();
  const [altImage, setAltImage] = useState<string>();

  useEffect(() => {
    if (src) {
      setSrcImage(src);
    }
  }, [src]);

  useEffect(() => {
    if (alt) {
      setAltImage(alt);
    }
  }, [alt]);

  const myImage = React.createElement(
    "img",
    {
      src: srcImage,
      alt: altImage,
      className: styles.react360img,
    },
    null
  );

  return <>{myImage}</>;
};

const React360: FC<React360Props> = ({ images, startIndex, handleClose }) => {
  const [imageData, setImageData] = useState<HTMLImageElement[]>();
  const [totalImages, setTotalImages] = useState<number>(0);
  const [showHover, setShowHover] = useState(true);
  const valueImages = Images360();
  const { imagesPreloaded } = useImagePreloader(valueImages);
  const stateContext = useContext(Context);

  useEffect(() => {
    if (imagesPreloaded) {
      const imagesArray = stateContext.state.layout.reserveCtaImages;
      setImageData(Object.values(imagesArray));

      if (imagesArray) {
        setTotalImages(Object.values(imagesArray).length);
      }
    }
  }, [imagesPreloaded]);

  const [state, setState] = useState({
    dragging: false,
    currentIndex: 0,
    dragStartIndex: 0,
    dragStart: 0,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (state.dragging) {
      if (e.srcEvent.screenX) {
        updateImageIndex(e.srcEvent.screenX);
      }
    }
  };

  const handleMouseDownAux = (e: any) => {
    setState({
      ...state,
      dragging: true,
      dragStart: e.srcEvent.screenX,
      dragStartIndex: state.currentIndex,
    });
  };

  const updateImageIndex = (currentPosition: number) => {
    const pixelsPerImage = pixelsPerDegree * (360 / totalImages);
    const { dragStart, currentIndex, dragStartIndex } = state;
    // pixels moved
    const dx = (dragStart - currentPosition) / pixelsPerImage;
    let index = Math.floor(dx) % totalImages;

    if (index < 0) {
      index = totalImages + index - 1;
    }
    index = (index + dragStartIndex) % totalImages;
    if (index !== currentIndex) {
      setState({ ...state, currentIndex: index });
    }
  };

  const preventDragHandler = (e: HammerInput) => {
    e.srcEvent.preventDefault();
  };

  const renderImage = () => {
    const { currentIndex } = state;

    return (
      <>
        <div className={styles.react360container} aria-label={""}>
          <ImageComponent
            src={imageData ? imageData[currentIndex].src : ""}
            alt={imageData ? imageData[currentIndex].alt : ""}
          />

          {showHover && (
            <div className={styles.hoverArea}>
              <h3>ARRASTE PARA VER O INTERIOR</h3>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <ReactHammer
      onPan={(e) => handleMouseMove(e)}
      onPress={(e) => preventDragHandler(e)}
      onPanStart={(e) => {
        setShowHover(false);

        preventDragHandler(e);
        handleMouseDownAux(e);
      }}
      onPanEnd={(e) => {
        // DataLayer
        setShowHover(true);
      }}
      direction={"DIRECTION_HORIZONTAL"}
    >
      <div className={styles.react360Wrapper}>{renderImage()}</div>
    </ReactHammer>
  );
};

export default React360;
