import { ui } from "@/assets";

export const dataSocial = [
  {
    id: "social-1",
    title: "Facebook",
    icon: ui.facebook,
    url: "https://www.facebook.com/fiatbr",
  },
  {
    id: "social-2",
    title: "Twitter",
    icon: ui.twitter,
    url: "https://twitter.com/fiatbr",
  },
  {
    id: "social-3",
    title: "Instagram",
    icon: ui.instagram,
    url: "https://www.instagram.com/fiatbr",
  },
  {
    id: "social-4",
    title: "Youtube",
    icon: ui.youtube,
    url: "https://www.youtube.com/user/fiat",
  },
];
